<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('student_reports')" :is-filter="false">
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('student_reports')" :is-filter="false">
                </HeaderMobile>
            </template>
            <div class="row data-form">
                <div class="col-12">
                    <ValidationObserver ref="form">
                        <div class="row">
                            <div class="col-12 col-sm-6">
                                <ValidationProvider name="report" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('report')">
                                        <multi-selectbox :allow-empty="false" :multiple="false"
                                                         :validate-error="errors[0]" :options="reportOptions"
                                                         v-model="form.report"></multi-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <!--                            <div class="col-12 col-sm-6" v-if="form.report=='1' ||form.report=='2' ">-->
                            <!--                                <ValidationProvider name="response_type" rules="required" v-slot="{valid, errors}"-->
                            <!--                                                    v-if="showReportType">-->
                            <!--                                    <b-form-group :label="$t('report_type')">-->
                            <!--                                        <multi-selectbox :allow-empty="false" :multiple="false"-->
                            <!--                                                         :validate-error="errors[0]" :options="responseTypeOptions"-->
                            <!--                                                         v-model="form.response_type"></multi-selectbox>-->
                            <!--                                    </b-form-group>-->
                            <!--                                </ValidationProvider>-->
                            <!--                            </div>-->
                            <div class="col-12 col-sm-6">
                                <ValidationProvider name="response_type" rules="required" v-slot="{valid, errors}"
                                                    v-if="showReportType">
                                    <b-form-group :label="$t('report_type')">
                                        <multi-selectbox :allow-empty="false" :multiple="false"
                                                         :validate-error="errors[0]" :options="responseTypeOptions"
                                                         v-model="form.response_type"></multi-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12">
                                <div class="row"
                                >
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4" v-if="showAcademicYear">
                                        <ValidationProvider name="academic_year"
                                                            :rules="form.report == 10 || form.report == 16 || form.report == 23 || form.report == 36 ? 'required' : ''"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('academic_year')">
                                                <academic-years-selectbox
                                                    v-model="form.academic_year"
                                                >
                                                </academic-years-selectbox>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>


                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4" v-if="showSemesterId">
                                        <ValidationProvider name="semester_id" :rules="form.report==16 || form.report==36  ? 'required':''"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('semester')">

                                                <semesters-selectbox v-model="form.semester_id"
                                                                     :validate-error="errors[0]">
                                                </semesters-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4" v-if="showSemester">
                                        <ValidationProvider name="semester"
                                                            :rules="form.report==16?'required':''"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('semester')">
                                                <SemesterTypeSelectbox
                                                    :validate-errors="errors[0]"
                                                    v-model="form.semester"
                                                />
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>


                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4" v-if="showDates">
                                        <ValidationProvider name="start_date" rules="required" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('start_date')">
                                                <select-date
                                                         v-model="form.start_date">
                                                </select-date>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4" v-if="showDates2">

                                        <ValidationProvider name="date" :rules="form.report==19 ?'':'required'"  v-slot="{ valid, errors }">


                                            <b-form-group :label="$t('date')">
                                                <select-date
                                                         v-model="form.date"
                                                         :validation-error="errors[0]">
                                                </select-date>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>


                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4" v-if="level">
                                        <ValidationProvider name="level" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('program_level')">
                                                <parameter-selectbox v-model="form.level"
                                                                     :validate-error="errors[0]"
                                                                     code="program_levels">

                                                </parameter-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>


                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4" v-if="showDates">
                                        <ValidationProvider name="end_date" rules="required">
                                            <b-form-group :label="$t('end_date')">
                                                <select-date
                                                         v-model="form.end_date">
                                                </select-date>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>


                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4" v-if="showDepartmentCode">
                                        <ValidationProvider name="department_code" rules="" v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('department')">
                                                <department-selectbox
                                                    v-model="form.department_code"
                                                    :validate-error="errors[0]"/>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>


                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4" v-if="showProgramCode">
                                        <ValidationProvider name="program_code" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('program')">
                                                <program-selectbox v-model="form.program_code"
                                                                   :department_code="form.department_code"
                                                                   :validate-error="errors[0]"></program-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4" v-if="showStudentNumber">
                                        <ValidationProvider name="student_number" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('student_number')">
                                                <b-form-input type="text" v-model="form.student_number"></b-form-input>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>


                                <div class="col-6 col-xs-12" v-if="showNumbers">
                                    <ValidationProvider name="number" rules="" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('student_numbers')">
                                            <lined-textarea v-model="form.number"
                                                            :nowrap="false"
                                                            :disabled="false"
                                                            :styles="{ height: '15em', resize: 'both' }"
                                                            :validateError="errors[0]">
                                            </lined-textarea>
                                            <div class="invalid-feedback d-block" v-if="errors[0]"
                                                 v-html="errors[0]"></div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>

                                <div class="col-6 col-xs-12  mb-3" v-if="registrationDateBetweenWithStatus">
                                    <ValidationProvider name="number" rules="" v-slot="{valid, errors}">
                                        <b-form-group
                                            :label="
                                        $t('registration_date_between')
                                            "
                                            class="mb-0"
                                        >
                                            <v-date-picker
                                                v-model="form.registration_date_between_with_status"
                                                is-range
                                                locale="en"
                                            >
                                                <template
                                                    v-slot="{
                                                        inputValue,
                                                        inputEvents,
                                                    }"
                                                >
                                                    <div
                                                        class="d-flex align-items-center"
                                                    >
                                                        <div
                                                            class="flex-grow-1"
                                                        >
                                                            <b-form-group
                                                                class="m-0 mr-1"
                                                            >
                                                                <input
                                                                    :value="
                                                                        inputValue.start
                                                                    "
                                                                    v-on="
                                                                        inputEvents.start
                                                                    "
                                                                    :placeholder="
                                                                        $t(
                                                                            'start_date'
                                                                        )
                                                                    "
                                                                    class="form-control"
                                                                />
                                                            </b-form-group>
                                                        </div>
                                                        <div
                                                            class="flex-grow-1"
                                                        >
                                                            <b-form-group
                                                                class="m-0 ml-1"
                                                            >
                                                                <input
                                                                    :value="
                                                                        inputValue.end
                                                                    "
                                                                    v-on="
                                                                        inputEvents.end
                                                                    "
                                                                    :placeholder="
                                                                        $t(
                                                                            'end_date'
                                                                        )
                                                                    "
                                                                    class="form-control"
                                                                />
                                                            </b-form-group>
                                                        </div>
                                                    </div>
                                                </template>
                                            </v-date-picker>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <processing-button @click="send" label="get_report"
                                                       :processing="processing"></processing-button>
                                </div>
                            </div>
                        </div>
                    </ValidationObserver>
                </div>

            </div>

        </app-layout>

    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout"
import Header from '@/layouts/AppLayout/Header'
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'
import {ValidationObserver, ValidationProvider} from "vee-validate";
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox";
import StudentReportService from "@/services/StudentReportService";
import ProcessingButton from "@/components/elements/ProcessingButton";
import LinedTextarea from "../../../components/elements/LinedTextarea";
import SemestersSelectbox from "../../../components/interactive-fields/SemestersSelectbox";
import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox.vue";
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox.vue";
import DepartmentSelectbox from "@/components/interactive-fields/DepartmentSelectbox.vue";
import qs from "qs";
import SemesterTypeSelectbox from "@/components/interactive-fields/SemesterTypeSelectbox.vue";

import { reports as reportData } from '@/modules/studentReports/data/data.js';
import ReportTypes from "@/modules/reports/data/StudentReportTypes";
import AttendanceService from "@/services/AttendanceService";

import moment from "moment";

export default {
    components: {
        SemesterTypeSelectbox,
        DepartmentSelectbox,
        ProgramSelectbox,
        AcademicYearsSelectbox,
        SemestersSelectbox,
        LinedTextarea,
        ProcessingButton,
        MultiSelectbox,
        AppLayout,
        ValidationProvider,
        ValidationObserver,
        Header,
        HeaderMobile
    },
    metaInfo() {
        return {
            title: this.$t('student_reports')
        }
    },
    data() {
        return {
            showSemester: false,
            showDates: false,
            showDates2: false,
            showSemesterId: false,
            level: false,
            showNumbers: false,
            showReportType: true,
            showAcademicYear: false,
            registrationDateBetweenWithStatus: false,
            showProgramCode: false,
            showDepartmentCode: false,
            showStudentNumber: false,
            form: {
                response_type: 'excel'
            },

            responseTypeOptions: [
                {value: 'page', text: this.$t('page')},
                {value: 'excel', text: this.$t('excel')},
                {value: 'pdf', text: 'PDF'},
            ],
            processing: false,
            reportName: "",
        }
    },
    computed: {
        reportOptions() {
            return reportData.filter((item) => {
                return this.checkPermission(item.permission)
            })
        }
    },


    methods: {
        async send() {
            const isValid = await this.$refs.form.validate();
            if (isValid) {
                this.processing = true;
                this.reportName = this.getOptionText(this.reportOptions, this.form.report);
                let config = {params: this.form};
                let isArrayBuffer = false;
                if (this.form.response_type != 'page') {
                    config.responseType = 'arraybuffer';
                    isArrayBuffer = false;
                }
                let oldNumber = this.form.number;
                if (oldNumber) {
                    config.params.number = config.params.number.split(/\n/);
                }

                if (this.form.report == '1') {
                    StudentReportService.registrationNumbersByFaculty(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                } else if (this.form.report == '2') {
                    StudentReportService.numberOfStudentByStatus(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                } else if (this.form.report == '3') {
                    StudentReportService.graduationDataOfRegistrar(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                } else if (this.form.report == '4') {
                    StudentReportService.demographicsOfCurrentStudents(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                } /*else if (this.form.report == '5') {
                    StudentReportService.numberOfCurrentStudentsBetweenSelectedDates(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                }/* else if (this.form.report == '6') {
                    StudentReportService.listOfStudentsInTheSelectedAcademicYear(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });

                }/* else if (this.form.report == '7') {

                    StudentReportService.undergraduateStudentsWhoTakeLessThanFullLoadOfCourses(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });

                } /*else if (this.form.report == '8') {

                    StudentReportService.graduateStudentsWhoTakeLessThanFullLoadOfCourses(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                }*/ else if (this.form.report == '9') {
                    StudentReportService.studentsWhoHaveNotEnrolledInAnyClassesInTheCurrentSemester(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                } else if (this.form.report == '10') {
                    const config = {
                        params: {
                            filter: {
                                semester: this.form.semester,
                                academic_year: this.form.academic_year,
                            },
                            response_type: this.form.response_type
                        },
                        paramsSerializer: (params) => qs.stringify(params, {encode: false}),
                    };
                    if (this.form.response_type != 'page') {

                        config.responseType = 'arraybuffer';
                        isArrayBuffer = false;
                    }
                    StudentReportService.studentReportsStudentsYearlyCourseListReport(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                } /*else if (this.form.report == '11') {
                    StudentReportService.studentReportsStudentsMustCourseListReport(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                }*/ else if (this.form.report == '12') {
                    StudentReportService.studentsWhoAreLikelyToGraduateInTheNext3Semesters(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                } else if (this.form.report == '13') {
                    StudentReportService.studentsWhoAreLikelyToGraduateInTheNext2Semesters(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                } else if (this.form.report == '14') {
                    StudentReportService.studentsWhoAreLikelyToGraduateNextSemester(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                } else if (this.form.report == '15') {
                    StudentReportService.studentsWhoAreLikelyToGraduateInTheCurrentSemester(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                } else if (this.form.report == '16') {
                    const config = {
                        params: {
                            filter: {
                                academic_year: this.form.academic_year,
                                semester: this.form.semester
                            }
                        },
                        paramsSerializer: (params) => qs.stringify(params, {encode: false}),
                    };
                    if (this.form.response_type != 'page') {
                        config.responseType = 'arraybuffer';
                        isArrayBuffer = false;
                    }
                    let oldNumber = this.form.number;
                    if (oldNumber) {
                        config.params.number = config.params.number.split(/\n/);
                    }

                    StudentReportService.sapReport(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });

                } else if (this.form.report == '17') {
                    const config = {
                        params: {
                            filter: {
                                level: this.form.level,
                                semester_id: this.form.semester_id,
                                date: this.form.date,

                            },
                            response_type: this.form.response_type
                        },
                        paramsSerializer: (params) => qs.stringify(params, {encode: false}),
                    };
                    if (this.form.response_type != 'page') {
                        config.responseType = 'arraybuffer';
                        isArrayBuffer = false;
                    }
                    let oldNumber = this.form.number;
                    if (oldNumber) {
                        config.params.number = config.params.number.split(/\n/);
                    }
                    StudentReportService.headCount(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                } else if (this.form.report == '18') {
                    const config = {
                        params: {
                            filter: {
                                semester_id: this.form.semester_id,
                                date: this.form.date,
                            },
                            response_type: this.form.response_type
                        },
                        paramsSerializer: (params) => qs.stringify(params, {encode: false}),
                    };
                    if (this.form.response_type != 'page') {
                        config.responseType = 'arraybuffer';
                        isArrayBuffer = false;
                    }
                    let oldNumber = this.form.number;
                    if (oldNumber) {
                        config.params.number = config.params.number.split(/\n/);
                    }
                    StudentReportService.degreeSeekingEnrollmentDegreesReport(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                } else if (this.form.report == '19') {
                    const config = {
                        params: {
                            filter: {
                                semester_id: this.form.semester_id,
                                date: this.form.date ? this.form.date : moment(new Date()).format('MM/DD/YYYY')
                            },
                            response_type: this.form.response_type
                        },
                        paramsSerializer: (params) => qs.stringify(params, {encode: false}),
                    };
                    if (this.form.response_type != 'page') {
                        config.responseType = 'arraybuffer';
                        isArrayBuffer = false;
                    }
                    let oldNumber = this.form.number;
                    if (oldNumber) {
                        config.params.number = config.params.number.split(/\n/);
                    }
                    StudentReportService.creditHoursGeneratedReport(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                } else if (this.form.report == '20') {
                    const config = {
                        params: {
                            filter: {
                                semester_id: this.form.semester_id,
                            },
                            response_type: this.form.response_type
                        },
                        paramsSerializer: (params) => qs.stringify(params, {encode: false}),
                    };
                    if (this.form.response_type != 'page') {
                        config.responseType = 'arraybuffer';
                        isArrayBuffer = false;
                    }
                    let oldNumber = this.form.number;
                    if (oldNumber) {
                        config.params.number = config.params.number.split(/\n/);
                    }
                    StudentReportService.diversityReport(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                } else if (this.form.report == '21') {
                    const config = {
                        params: {
                            filter: {
                                semester_id: this.form.semester_id,
                                date: this.form.date
                            },
                            response_type: this.form.response_type
                        },
                        paramsSerializer: (params) => qs.stringify(params, {encode: false}),
                    };
                    if (this.form.response_type != 'page') {
                        config.responseType = 'arraybuffer';
                        isArrayBuffer = false;
                    }
                    let oldNumber = this.form.number;
                    if (oldNumber) {
                        config.params.number = config.params.number.split(/\n/);
                    }
                    StudentReportService.enrollmentReport(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                } else if (this.form.report == '22') {
                    const config = {
                        params: {
                            filter: {
                                semester_id: this.form.semester_id,
                            },
                            response_type: this.form.response_type
                        },
                        paramsSerializer: (params) => qs.stringify(params, {encode: false}),
                    };
                    if (this.form.response_type != 'page') {
                        config.responseType = 'arraybuffer';
                        isArrayBuffer = false;
                    }
                    let oldNumber = this.form.number;
                    if (oldNumber) {
                        config.params.number = config.params.number.split(/\n/);
                    }
                    StudentReportService.AdmissionReport(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                } else if (this.form.report == '23') {
                    const config = {
                        params: {
                            filter: {
                                semester: this.form.semester,
                                academic_year: this.form.academic_year,
                                student_number: this.form.student_number
                            },
                            response_type: this.form.response_type
                        },
                        paramsSerializer: (params) => qs.stringify(params, {encode: false}),
                    };
                    if (this.form.response_type != 'page') {

                        config.responseType = 'arraybuffer';
                        isArrayBuffer = false;
                    }
                    let oldNumber = this.form.number;
                    if (oldNumber) {
                        config.params.number = config.params.number.split(/\n/);
                    }
                    StudentReportService.RegistrarReport(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                }
                else if (this.form.report == '32') {
                    const config = {
                        params: {
                            filter: {
                                semester: this.form.semester,
                                academic_year: this.form.academic_year,
                                student_number: this.form.student_number
                            },
                            response_type: this.form.response_type
                        },
                        paramsSerializer: (params) => qs.stringify(params, {encode: false}),
                    };
                    if (this.form.response_type != 'page') {

                        config.responseType = 'arraybuffer';
                        isArrayBuffer = false;
                    }
                    let oldNumber = this.form.number;
                    if (oldNumber) {
                        config.params.number = config.params.number.split(/\n/);
                    }
                    StudentReportService.financialReport(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                }
                else if (this.form.report == '24') {
                    const config = {
                        params: {
                            filter: {
                                semester_id: this.form.semester_id,

                            },
                            response_type: this.form.response_type
                        },
                        paramsSerializer: (params) => qs.stringify(params, {encode: false}),
                    };
                    if (this.form.response_type != 'page') {

                        config.responseType = 'arraybuffer';
                        isArrayBuffer = false;
                    }
                    let oldNumber = this.form.number;
                    if (oldNumber) {
                        config.params.number = config.params.number.split(/\n/);
                    }
                    StudentReportService.courseApprovalCheckReport(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                } /*else if (this.form.report == '25') {
                    const config = {
                        params: {
                            filter: {
                                registration_semester: this.form.semester,
                                registration_academic_year:this.form.academic_year

                            },
                            response_type: this.form.response_type
                        },
                        paramsSerializer: (params) => qs.stringify(params, {encode: false}),
                    };
                    if (this.form.response_type != 'page') {

                        config.responseType = 'arraybuffer';
                        isArrayBuffer = false;
                    }
                    let oldNumber = this.form.number;
                    if (oldNumber) {
                        config.params.number = config.params.number.split(/\n/);
                    }
                    StudentReportService.courseRegistrationReport(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                }*/
                else if (this.form.report == '26') {
                    StudentReportService.registrationReport(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                }/*
                else if (this.form.report == '27') {
                    AttendanceService.entryTrackingReport(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                } */else if (this.form.report == '28') {
                    StudentReportService.missingDocumentReport(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                }
                else if (this.form.report == '29') {
                    const config = {
                        params: {
                            filter: {
                                graduation_semester: this.form.semester,
                                graduation_academic_year: this.form.academic_year,
                            },

                        },

                        paramsSerializer: (params) => qs.stringify(params, {encode: false}),
                    };
                    config.responseType = 'arraybuffer';



                    StudentReportService.studentReportsParchmentReport(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                }
                else if (this.form.report == '30') {
                    const config = {
                        params: {
                            filter: {
                                semester_id: this.form.semester_id,
                            },
                        },

                        paramsSerializer: (params) => qs.stringify(params, {encode: false}),
                    };
                    config.responseType = 'arraybuffer';

                    AttendanceService.dailyAttendanceReport(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                }
               /* else if (this.form.report == '31') {
                    StudentReportService.studentVirtualInternshipReport(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                } */ else if (this.form.report == '33') {
                  const config = {
                    params: {
                      filter: {
                        has_course_in_semester : this.form.semester_id,
                      },
                    },

                    paramsSerializer: (params) => qs.stringify(params, {encode: false}),
                  };
                  config.responseType = 'arraybuffer';

                  StudentReportService.studentReportsPresidentListReport(config).then(this.callback).catch(e => {
                    this.showErrors(e, this.$refs.form, isArrayBuffer);
                  }).finally(() => {
                    this.processing = false;
                  });
                }

                else if (this.form.report == '34') {
                    StudentReportService.studentCurriculumCreditstReport().then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
            }
                else if (this.form.report == '35') {
                    const config = {
                        params: {
                            filter: {
                                semester: this.form.semester,
                                academic_year: this.form.academic_year,
                                student_number: this.form.student_number
                            },
                            response_type: this.form.response_type
                        },
                        paramsSerializer: (params) => qs.stringify(params, {encode: false}),
                    };
                    if (this.form.response_type != 'page') {

                        config.responseType = 'arraybuffer';
                        isArrayBuffer = false;
                    }
                    let oldNumber = this.form.number;
                    if (oldNumber) {
                        config.params.number = config.params.number.split(/\n/);
                    }
                    StudentReportService.ieReporting(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                } else if (this.form.report == '36') {
                    const config = {
                        params: {
                            filter: {
                                semester: this.form.semester,
                                academic_year: this.form.academic_year,
                            },
                            response_type: this.form.response_type
                        },
                        paramsSerializer: (params) => qs.stringify(params, {encode: false}),
                    };
                    if (this.form.response_type != 'page') {

                        config.responseType = 'arraybuffer';
                        isArrayBuffer = false;
                    }
                    let oldNumber = this.form.number;
                    if (oldNumber) {
                        config.params.number = config.params.number.split(/\n/);
                    }
                    StudentReportService.dsoRegistrationReport(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                }
                this.form.number = oldNumber;
            }
        },
        callback(response) {
            if (this.form.response_type == 'page') {
                this._openFile(response);
            } else if (this.form.response_type == 'excel') {
                this._downloadFile(response, this.reportName + '.xlsx');
            } else if (this.form.response_type == 'pdf') {
                this._downloadFile(response, this.reportName + '.pdf');
            }
        }
    },
    watch: {
        'form.report'(newVal, oldVal) {
            if (newVal != oldVal) {
                this.form = {response_type: 'excel', report: newVal}
            }
            this.showDates = false;
            this.showDates2 = false;
            this.level = false;
            this.showSemester = false;
            this.showReportType = true;
            this.showNumbers = false;
            this.showAcademicYear = false
            this.registrationDateBetweenWithStatus = false
            this.showProgramCode = false
            this.showDepartmentCode = false
            this.showSemesterId = false

            if (newVal == '1') {
                this.showSemesterId = true;
            }/*
            else if (newVal == '6') {
                this.showSemester = true
                this.showAcademicYear = true

            }/*

            else if (newVal == '11') {
                this.registrationDateBetweenWithStatus = true
            }*/

            else if (newVal == '10') {
                this.showSemester = true
                this.showAcademicYear = true
            }
            else if (newVal == '16') {
                this.showSemester = true
                this.showAcademicYear = true
                this.showProgramCode = true
                this.showDepartmentCode = true
            }
            else if (newVal == '17') {
                this.showDates2 = false
                this.level = true
                this.showSemesterId = true
            }
            else if (newVal == '18') {
                this.showDates2 = false
                this.showSemesterId = true
            }
            else if (newVal == '19') {
                this.showDates2 = true
                this.showSemesterId = true
            }
            else if (newVal == '20' || newVal == '21' || newVal == "22") {
                if (newVal == "21") {
                    this.showDates2 = true
                }
                this.showSemesterId = true
            }
            else if (newVal == '23') {
                this.showSemester = true
                this.showAcademicYear = true
                this.showStudentNumber = true
            }
            else if (newVal == '32') {
                this.showSemester = true
                this.showAcademicYear = true
                this.showStudentNumber = true
            }
            else if (newVal=='24'){
                this.showSemesterId  = true

            }
            else if (newVal=='25'){
                this.showSemester  = true
                this.showAcademicYear=true
            }
            else if (newVal=='26'){
                this.showSemester  = true
                this.showAcademicYear=true
            }
            else if (newVal=='29'){
                this.showSemester  = true
                this.showAcademicYear=true
            }
            else if (newVal=='30'){
                this.showSemesterId  = true
                this.showReportType = false;
            }
            else if (newVal=='33'){
              this.showSemesterId  = true
            }
        else if (newVal=='34'){
                this.showReportType  = false
            }
            else if (newVal == '35') {
                this.showSemester = true
                this.showAcademicYear = true
                this.showStudentNumber = true
            }
            else if (newVal == '36') {
                this.showSemester = true
                this.showAcademicYear = true
            }
        }
    },

};
</script>

